<template>
    <div class="page">
        <div class="flex between" style="margin-bottom: 24px;">
            <h4>{{$t('user.list')}}</h4>
            <div style="text-align: right;">
                <a-button :disabled="!userInfo.ifAdmin" type="primary" style="margin-right: 24px;" @click="createUser.show=true">{{$t('user.create')}}</a-button>
                <a-button :disabled="!userInfo.ifAdmin" style="margin-right: 24px;" @click="resetRow(selectedRows)">{{$t('user.resetPwd')}}</a-button>
                <a-button :disabled="!userInfo.ifAdmin" @click="delRow(selectedRows)">{{$t('user.delRows')}}</a-button>
            </div>
        </div>
        <a-table rowKey="id" :scroll="{ x:1000, y: 400 }" :columns="columns" :data-source="data" :pagination="pagination" :row-selection="rowSelection">
            <template #acct="{record}">
                <span>{{record.acct}}</span>
                <a-button v-if="record.ext"  danger style="border-color: transparent;cursor: auto;" ghost>{{$t('user.ext')}}</a-button>
                <a-button v-if="record.extOver"  style="color: #EBC730;border-color: transparent;cursor: auto;"  ghost>{{$t('user.extNot')}}</a-button>
            </template>
            <template #activeState="{record}">
                <a-badge v-if="record.actionState" status="success" :text="$t('enable')" />
                <a-badge v-else status="error" :text="$t('disabled')" />
            </template>

            <template #action="{record}">
                <a v-if="userInfo.ifAdmin" @click.stop="updateUserFunc(record)">{{$t('edit')}}</a>
                <a v-else disabled >{{$t('edit')}}</a>
                <a-divider type="vertical"/>
                <a-popconfirm
                        v-if="userInfo.id!==record.id&&userInfo.ifAdmin"
                        :title="record.actionState?$t('user.disabledPlz'):$t('user.enabledPlz')"
                        :ok-text="$t('ok')"
                        :cancel-text="$t('cancel')"
                        @confirm="rowActionStateFunc(record)">
                    <a v-if="record.actionState">{{$t('disabled')}}</a>
                    <a v-else>{{$t('enable')}}</a>
                </a-popconfirm>
                <a disabled v-if="userInfo.id===record.id||!userInfo.ifAdmin">{{record.actionState?$t('disabled'):$t('enable')}}</a>
                <a-divider type="vertical"/>
                <a v-if="userInfo.ifAdmin" @click="resetRow([record])" >{{$t('reset')}}</a>
                <a v-else disabled >{{$t('reset')}}</a>
            </template>
        </a-table>
        <create-user :show="createUser.show" @close="()=>{createUser.show=false;rowSelection.selectedRowKeys=[]}"/>
        <update-user :info="updateUser.info" :show="updateUser.show" @close="()=>{updateUser.show=false;rowSelection.selectedRowKeys=[]}"/>
    </div>
</template>

<script>
    import i18n from "../../../locale/main";
    import CreateUser from "./createUser";
    import {reactive,createVNode,ref,computed} from 'vue'
    import UpdateUser from "./updateUser";
    import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
    import { Modal,message } from 'ant-design-vue';
    import { useStore } from 'vuex';
    import {updateStatus,reset,remove} from "../../../service/user";
    export default {
        components: {UpdateUser, CreateUser},
        setup() {
            let t = i18n.global.t
            let store = useStore()
            let userInfo=computed(()=>store.state.user.userInfo)
            const selectedRows=ref([])
            const createUser=reactive({
                show:false,
            })
            const updateUser=reactive({
                show:false,
                info:{},
            })
            const columns = [
                {
                    title: t('user.acct'),
                    width: 300,
                    slots: {customRender: 'acct'},
                },
                {title: t('user.type'), width: 140, dataIndex: 'type',ellipsis:true,},
                {title: t('user.attr'), width: 140, dataIndex: 'attr',ellipsis:true,},
                {title: t('user.extTime'), width: 200, dataIndex: 'extTime',ellipsis:true,},
                {title: t('createTime'), width: 200, dataIndex: 'createTime',ellipsis:true,},
                {
                    title: t('user.activeState'),
                    slots: {customRender: 'activeState'},
                    width: 100,
                },
                {
                    title: t('action'),
                    fixed: 'right',
                    width: 200,
                    slots: {customRender: 'action'},
                },
            ].map((r,k) => {
                r.key = k;
                return r
            });

            const rowSelection =reactive( {
                getCheckboxProps:(record) => ({
                    disabled: (userInfo.value.id===record.id||record.tenantId!==0)?true:false
                }),
                selectedRowKeys:[],
                onChange: (selectedRowKeys , rows) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                    selectedRows.value = rows
                },
            });
            const pagination=reactive({
                current:computed(()=>store.state.user.current),
                pageSize:computed(()=>store.state.user.size),
                pageSizeOptions:store.state.user.sizeAll,
                total:computed(()=>store.state.user.total),
                showSizeChanger:true,
                showTotal:(total)=>t('pages.total',{num:total}),
                async onChange(val){
                    await store.dispatch('user/search',{
                        current:val,
                        size:store.state.user.size,
                    })
                },
                async onShowSizeChange(_,size){
                    await store.dispatch('user/search',{
                        current:1,
                        size:size,
                    })
                },
            })
            return {
                createUser,
                updateUser,
                userInfo,
                resetRow(rows=[]){
                    if(rows.length===0){
                        return message.error(t('user.select'))
                    }
                    Modal.confirm({
                        title: t('user.resetPwd'),
                        icon: createVNode(ExclamationCircleOutlined),
                        content:  t('user.resetPwdPlz'),
                        okText: t('ok'),

                        async onOk(){
                            await reset({ids:rows.map(r=>r.id)})
                            await store.dispatch('user/search',{
                                current:1,
                                size:store.state.user.size,
                            })
                            rowSelection.selectedRowKeys=[]
                            message.success(t('actionOk'))
                        },
                        cancelText: t('cancel'),
                    });
                },
                delRow(rows=[]){
                    if(rows.length===0){
                        return message.error(t('user.select'))
                    }
                    Modal.confirm({
                        title: t('user.delRows'),
                        icon: createVNode(ExclamationCircleOutlined),
                        content:  t('user.delRowsDesc1'),
                        okText: t('ok'),

                        async onOk(){
                            await remove({ids:rows.map(r=>r.id)})
                            await store.dispatch('user/search',{
                                current:1,
                                size:store.state.user.size,
                            })
                            rowSelection.selectedRowKeys=[]
                            message.success(t('actionOk'))
                        },
                        cancelText: t('cancel'),
                    });
                },
                async rowActionStateFunc(row){
                    await updateStatus(row)
                    await store.dispatch('user/search',{
                        current:1,
                        size:store.state.user.size,
                    })
                },
                updateUserFunc(record){
                    updateUser.show=true;updateUser.info=record;
                },
                rowSelection,
                selectedRows,
                columns,
                pagination,
                data: computed(()=>store.state.user.data)
            }
        }
    }
</script>

<style scoped>

</style>