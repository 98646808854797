<template>
    <a-row :gutter="[48,24]" class="search-form">
        <a-col :sm="14" :xxl="6">
            <a-form-item :label="$t('user.search')" :colon="false">
                <a-input allowClear v-model:value="formState.search" @keypress.enter="formState.searchHandle" :placeholder="$t('user.searchPlz1')" />
            </a-form-item>
        </a-col>
        <a-col :sm="10" :xxl="6">
            <a-form-item :label="$t('user.status')" :colon="false">
                <a-select
                        ref="select"
                        :placeholder="$t('plz.select')"
                        style="width: 100%"
                        allowClear
                        v-model:value="formState.status"
                        @change="formState.searchHandle">
                    <a-select-option value="true">{{$t('enable')}}</a-select-option>
                    <a-select-option value="false">{{$t('disabled')}}</a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :sm="12" :xxl="8">
            <a-form-item :label="$t('user.expTime')" :colon="false">
                <a-range-picker allowClear style="width: 220px;" @change="formState.searchHandle" v-model:value="formState.date" />
            </a-form-item>
        </a-col>
        <a-col  :sm="12" :xxl="4">
            <a-form-item :wrapper-col="{offset:8,span:16}" :colon="false" style="text-align: right;">
                <a-button @click="formState.reset">{{$t('reset')}}</a-button>
            </a-form-item>
        </a-col>
    </a-row>
</template>

<script>
    import {reactive,toRaw,onMounted} from 'vue'
    import {useStore} from "vuex";
    export default {
        setup(){
            let store = useStore()
            let formState=reactive({
                search:null,
                status:null,
                date:[],
                searchHandle(){
                    //console.log(toRaw(formState))
                    store.dispatch('user/search',{searchForm:toRaw(formState),current:1,search:true})
                },
                reset(){
                    formState.search=null
                    formState.status=null
                    formState.date=[]
                    store.dispatch('user/search',{searchForm:toRaw(formState),current:1,search:true})
                }
            })
            onMounted(()=>{
                formState.searchHandle()
            })
            return {
                formState
            }
        }
    }
</script>

<style lang="scss" scoped>
.search-form{
    ::v-deep(.ant-form-item){
        margin-bottom: 0;
    }
}
</style>