<template>
    <a-modal :visible="show" :title="$t('edit')" @cancel="handleCancel">
        <template #footer>
            <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
            <a-button key="submit" type="primary" :loading="loading" :disabled="disabledDate(formState.extTime)" @click="handleOk">{{$t('ok')}}</a-button>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules" :colon="false" :label-col="{span:6}"
                :wrapper-col="{span:18}">
            <a-form-item :label="$t('user.extTime')" name="extTime">
                <a-date-picker :showToday="false" @openChange="datePicker.openChange" :open="datePicker.open"  style="width: 100%" v-model:value="formState.extTime" :disabledDate="disabledDate">
                    <template #renderExtraFooter>
                        <div style="text-align: center">
                            <a @click="formState.fastChoice">{{$t('forever')}}</a>
                        </div>
                    </template>
                </a-date-picker>
            </a-form-item>

        </a-form>

    </a-modal>
</template>

<script>
    import {ref, reactive, toRaw} from 'vue'
    import i18n from "../../../locale/main";
    import {message} from 'ant-design-vue'
    import {watch} from 'vue'
    import moment from 'moment'
    import {updateInfo} from '../../../service/user'
    import {useStore} from "vuex";
    export default {
        emits: ['close'],
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            info: {
                type: Object,
                default:null,
            }
        },
        setup(props, ctx) {
            let store = useStore()
            let t = i18n.global.t
            const formState = reactive({

                extTime: null,
                fastChoice(){
                    formState.extTime=moment().add(100,'years')
                    datePicker.open=false
                }
            })
            const datePicker = reactive({
                open:false,
                openChange(state){
                    datePicker.open=state
                },
            });
            const formRef = ref();
            const loading = ref(false);

            watch(()=>props.info?.extTime,()=>{
                formState.extTime=moment(moment(props?.info?.extTime).format('yyyy-MM-DD'))
            })

            const extTimeV = (rule, value) => {
                if (!value) return Promise.reject(t('user.extTime'))
                return Promise.resolve();
            }
            const rules = {
                extTime: [{required: true, validator: extTimeV, trigger: 'blur',},],
            }


            const handleOk = async () => {
                try {

                    await formRef.value.validate()
                    loading.value = true;
                    let data=toRaw(formState)
                    await updateInfo({...data,id:props.info.id})

                    loading.value = false;
                    ctx.emit('close', false)
                    message.success(t('updateOk'))
                    formRef.value.resetFields()

                    await store.dispatch('user/search',{
                        current:1,
                        size:store.state.user.size,
                    })
                } catch (e) {
                    loading.value = false;
                    console.log(e)
                }
            };

            const handleCancel = () => {
                formRef.value.resetFields()
                ctx.emit('close', false)
            };

            return {
                loading,
                formRef,
                formState,
                datePicker,
                rules,

                handleOk,
                handleCancel,
                disabledDate(dete){
                    return moment(dete).diff(moment().startOf('days'),'days')>0?false:true;
                }
            };
        }
    }
</script>

<style scoped>

</style>