<template>
    <a-modal  :visible="show" :title="$t('user.create')" @cancel="handleCancel">
        <template #footer>
            <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">{{$t('ok')}}</a-button>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules" :colon="false" :label-col="{span:6}" :wrapper-col="{span:18}">
            <a-form-item :label="$t('user.acct')" name="acct">
                <a-input :placeholder="$t('login.plzAcc1')" v-model:value="formState.acct"/>
            </a-form-item>
            <a-form-item :label="$t('user.pwd')" name="pwd">
                <a-tooltip>
                    <template #title>{{$t('login.plzPwd2')}}</template>
                    <a-input-password :placeholder="$t('login.plzPwd1')" v-model:value="formState.pwd"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item :label="$t('user.name')" name="name">
                <a-input :placeholder="$t('user.plzName')" v-model:value="formState.name"/>
            </a-form-item>
            <a-form-item :label="$t('user.extTime')" name="extTime">
                <a-date-picker :showToday="false" @openChange="datePicker.openChange" :open="datePicker.open" style="width: 100%" v-model:value="formState.extTime" :disabledDate="disabledDate" >
                    <template #renderExtraFooter>
                       <div style="text-align: center">
                           <a @click="formState.fastChoice">{{$t('forever')}}</a>
                       </div>
                    </template>
                </a-date-picker>
            </a-form-item>
            <a-form-item :label="$t('user.acctType')" name="userType">
                <a-radio-group v-model:value="formState.userType">
                    <a-radio value="admin">{{$t('user.type1')}}</a-radio>
                    <a-radio value="user">{{$t('user.type2')}}</a-radio>
                </a-radio-group>
            </a-form-item>
            <a-form-item :label="$t('user.activeState')" name="activeState" >
                <div class="flex left">
                    <a-switch v-model:checked="formState.activeState" style="margin-right: 10px;"/>
                    <span v-if="formState.activeState">{{$t('enable')}}</span>
                    <span v-else>{{$t('disabled')}}</span>
                </div>
            </a-form-item>
        </a-form>

    </a-modal>
</template>

<script>
    import {ref, reactive, toRaw, watch} from 'vue'
    import i18n from "../../../locale/main";
    import {message} from 'ant-design-vue'
    import {create} from '../../../service/user'
    import {useStore} from "vuex";
    import moment from "moment";
    export default {
        emits:['close'],
        props:{
          show:{
              type:Boolean,
              default:false,
          }
        },
        setup(props,ctx) {
            let t = i18n.global.t
            let store = useStore()
            const formState = reactive({
                acct:'',
                pwd:'',
                name:'',
                extTime:null,
                userType:"admin",
                activeState:true,
                fastChoice(){
                    formState.extTime=moment().add(100,'years')
                    datePicker.open=false
                }
            })
            const formRef = ref();
            const datePicker = reactive({
                open:false,
                openChange(state){
                    datePicker.open=state
                },
            });
            const loading = ref(false);


            watch(()=>props.show,()=>{
                if(props.show){
                    formState.acct=''
                    formState.pwd=''
                    formState.name=""
                    formState.extTime=null
                    formState.userType="admin"
                    formState.activeState=true
                }
            })

            const extTimeV=(rule, value)=>{
                if(!value)return Promise.reject(t('user.extTime'))
                return Promise.resolve();
            }

            const validate=(rule,value)=>{
                if(!value)return Promise.reject(t('login.plzAcc1'))
                if(!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value)){
                    return Promise.reject(t('editEmailPlz2'))
                }
                return  Promise.resolve()
            }
            let pwdReg =new RegExp('^[0-9A-Za-z_!@#$%^&*]{6,32}$') ;
            let validatePwd = async (rule, value) => {
                if(!value){
                    return Promise.resolve();
                }
                if(!pwdReg.test(value)){
                    return Promise.reject(t('pwdPlz'))
                }

                return Promise.resolve();
            };
            const rules={
                acct: [
                    {required: true, validator: validate, trigger: 'blur',},
                ],
                pwd: [{ validator: validatePwd, trigger: 'blur',},],
                name: [{required: true, message: t('user.plzName'), trigger: 'blur',},],
                extTime: [{required: true, validator:extTimeV, trigger: 'blur',},],
            }



            const handleOk = async () => {
                try {

                    await formRef.value.validate()
                    loading.value = true;
                    console.log(toRaw(formState))
                    let data=toRaw(formState)
                    await create(data)

                    await store.dispatch('user/search',{
                        current:1,
                        size:store.state.user.size,
                    })

                    loading.value = false;
                    ctx.emit('close',false)
                    message.success(t('createOk'))
                    formRef.value.resetFields()


                }catch (e) {
                    loading.value = false;
                    console.log(e)
                }
            };

            const handleCancel = () => {
                formRef.value.resetFields()
                ctx.emit('close',false)
            };

            return {
                loading,
                formRef,
                datePicker,
                formState,
                rules,
                disabledDate(dete){
                    return moment(dete).diff(moment().startOf('days'),'days')>0?false:true;
                },
                handleOk,
                handleCancel,

            };
        }
    }
</script>

<style scoped>

</style>